import { tryJsonParse } from '../../../../utils';
import { ExecutionMetadata, getExecutionViews } from '../execution';
import { StudioVizConfig } from '../visualizations';

import { GetDashboardResponse, GetDashboardResponseRaw } from './dashboard.model';
import { LayoutConfig, LayoutItemType } from './dashboard-layout.model';

const getPatchVisualizationsFromLayout = (
    layoutConfig?: LayoutConfig,
): { id: string; isFeatured?: boolean }[] | undefined => {
    if (!layoutConfig) return undefined;

    const layoutVisualizations = layoutConfig?.lg
        ?.filter(item => item.data.type !== LayoutItemType.Markdown)
        ?.map(item => ({ id: item.data.id, isFeatured: item.data.isFeatured ?? false }));

    return layoutVisualizations?.length > 0 ? layoutVisualizations : undefined;
};

const parseDashboardVisualizationConfigs = (visualizations: GetDashboardResponseRaw['data']['visualizations']) => {
    return visualizations.map(viz => ({
        ...viz,
        config: tryJsonParse<StudioVizConfig<any>>(viz.config).parsedJson,
    }));
};

const parseDashboardVisualizations = (
    shouldParseExecutionData: boolean,
    visualizations: GetDashboardResponseRaw['data']['visualizations'],
): GetDashboardResponse['data']['visualizations'] => {
    if (!visualizations?.length) return visualizations as [];

    const visualizationsWithParsedConfigs = parseDashboardVisualizationConfigs(visualizations);

    if (!shouldParseExecutionData) {
        return visualizationsWithParsedConfigs;
    }

    return visualizationsWithParsedConfigs.map(viz => ({
        ...viz,
        execution: {
            executionId: viz.lastExecutionId,
            queryId: viz.queryId,
            rows: tryJsonParse<any[]>(viz.executionRows).parsedJson,
            metadata: {
                ...(tryJsonParse<ExecutionMetadata>(viz.executionMetadata).parsedJson ?? {}),
                ...getExecutionViews({
                    metadata: tryJsonParse<any>(viz.executionMetadata).parsedJson,
                    rows: tryJsonParse<any[]>(viz.executionRows).parsedJson,
                }),
            },
        },
    }));
};

export { getPatchVisualizationsFromLayout, parseDashboardVisualizationConfigs, parseDashboardVisualizations };
