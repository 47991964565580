import { isServerSide } from '../../../../utils';

const STUDIO_AUTH_HEADER_KEY = 'X-Auth-Secret';
const STUDIO_AUTH_SECRET = process.env.X_AUTH_SECRET as string;

const getUrlBoxAuthParam = () => {
    if (isServerSide()) return;
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('xAuthSecret');
};

const getStudioHeaders = () => ({
    [STUDIO_AUTH_HEADER_KEY]: getUrlBoxAuthParam() ?? STUDIO_AUTH_SECRET,
});

export { getStudioHeaders };
