import { createApi } from '../../../config/create-api';
import { getStudioHeaders } from '../config/headers';

import {
    DeleteTagRequest,
    DeleteTagResponse,
    GetTagRequest,
    GetTagResponse,
    GetTagsRequest,
    GetTagsResponse,
    PatchUpdateTagRequest,
    PatchUpdateTagResponse,
    PostCreateTagRequest,
    PostCreateTagResponse,
    STUDIO_TAGS_ENDPOINTS,
} from './tags.model';

const studioTagsApi = createApi(build => ({
    get: {
        tag: build<GetTagResponse, GetTagRequest>({
            cachePrefix: 'tag-by-id',
            baseUrl: 'research-api',
            query: ({ id }) => {
                return {
                    uri: STUDIO_TAGS_ENDPOINTS.GET.TAG({ id }),
                    options: {
                        headers: getStudioHeaders(),
                    },
                };
            },
        }),
        tags: build<GetTagsResponse, GetTagsRequest>({
            cachePrefix: 'all-tags',
            baseUrl: 'research-api',
            query: ({ ids, page = 0, limit = 100 }) => {
                return {
                    uri: STUDIO_TAGS_ENDPOINTS.GET.TAGS(),
                    options: {
                        headers: getStudioHeaders(),
                    },
                    params: {
                        ids,
                        page,
                        limit,
                    },
                };
            },
        }),
    },
    patch: {
        updateTag: build<PatchUpdateTagResponse, PatchUpdateTagRequest>({
            cachePrefix: 'update-tag',
            baseUrl: 'research-api',
            query: ({ id, ...body }) => {
                return {
                    uri: STUDIO_TAGS_ENDPOINTS.PATCH.TAG({ id }),
                    options: {
                        headers: getStudioHeaders(),
                        method: build.Method.Patch,
                        body,
                    },
                };
            },
        }),
    },
    post: {
        createTag: build<PostCreateTagResponse, PostCreateTagRequest>({
            cachePrefix: 'create-tag',
            baseUrl: 'research-api',
            query: body => {
                return {
                    uri: STUDIO_TAGS_ENDPOINTS.POST.TAG(),
                    options: {
                        headers: getStudioHeaders(),
                        method: build.Method.Post,
                        body,
                    },
                };
            },
        }),
    },
    delete: {
        tag: build<DeleteTagResponse, DeleteTagRequest>({
            cachePrefix: 'delete-tag',
            baseUrl: 'research-api',
            query: ({ id }) => {
                return {
                    uri: STUDIO_TAGS_ENDPOINTS.DELETE.TAG({ id }),
                    options: {
                        headers: getStudioHeaders(),
                        method: build.Method.Delete,
                    },
                };
            },
        }),
    },
}));

export { studioTagsApi };
