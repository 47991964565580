import { Control, FieldValues } from 'react-hook-form';
import { tagMutations } from '@studio/api/tags/mutations';
import { tagQueries } from '@studio/api/tags/queries';
import * as yup from 'yup';

import { useBoolean } from '@blockworks/platform/hooks';
import { Button, FlexBox, Modal, Text } from '@blockworks/ui';
import { Bit } from '@blockworks/ui/bit';
import { Form } from '@blockworks/ui/forms/form';

type CreateOrSelectTagsProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    selected?: string[];
};

const DEFAULT_COLOR = '#000000';

const createTagSchema = yup.object({
    name: yup.string().required('A name is required.'),
    color: yup
        .string()
        .required('A color is required.')
        .matches(/^#?[0-9a-fA-F]{6}$/, 'Must be a valid hex value.'),
});

export const CreateOrSelectTags = ({ control: entityControl, selected }: CreateOrSelectTagsProps<any>) => {
    const tagModal = useBoolean(false);

    // Control for a new tag created within this component
    const {
        control: tagControl,
        handleSubmit,
        reset,
        setError,
    } = Form.use({
        resolver: Form.resolvers.yup(createTagSchema),
    });

    const { data: tagOptions, refetch: refetchTags } = tagQueries.getTags.use({
        variables: {},
        select: tagQueries.getTags.select.selectTagsAsOptions,
    });

    const createTag = tagMutations.createTag.use({
        onSuccess: () => {
            handleOnTagClose();
            refetchTags();
        },
        onError: error => {
            if (error.message.includes('Duplicate')) {
                setError('name', {
                    message: 'This tag already exists',
                });
            } else {
                setError('name', {
                    message: error.message,
                });
            }
        },
    });

    const handleCreateTagSubmit = handleSubmit(async ({ name, color }) => {
        color = color.replace('#', '');
        try {
            const res = await createTag.mutateAsync({
                name,
                color,
            });
            if (!res?.data?.id) {
                throw new Error('Tag was not created properly.');
            }
        } catch (error) {
            console.error(error);
        }
    });

    const handleOnTagClose = () => {
        reset({ name: '', color: DEFAULT_COLOR });
        tagModal.setFalse();
    };

    return (
        <>
            <Modal
                open={tagModal.value}
                onClose={handleOnTagClose}
                classNames={{
                    base: 'w-[90%] h-[425px] md:w-[500px]',
                }}
            >
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>{`Create new tag`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="gap-4">
                        <FlexBox gap="sm">
                            <FlexBox col gap="xs" w="1/2">
                                <Form.Input label="Name" name="name" size="sm" control={tagControl} />
                                <Form.Input
                                    label="Color (hex)"
                                    name="color"
                                    defaultValue={DEFAULT_COLOR}
                                    size="sm"
                                    control={tagControl}
                                />
                            </FlexBox>
                            <Bit.div pl={4} pt={4}>
                                <Form.ColorPicker name="color" control={tagControl} hideErrorMessage />
                            </Bit.div>
                        </FlexBox>
                    </Modal.Body>
                    <Modal.Footer>
                        <Form.Submit
                            size="2sm"
                            type="button"
                            borderRadius="md"
                            onClick={handleCreateTagSubmit}
                            loading={createTag.isPending}
                            loadingText="Creating Tag"
                        >
                            {`Create Tag`}
                        </Form.Submit>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <Form.MultiSelect
                label={'Tags'}
                name={'tagIds'}
                placeholder="No tags"
                defaultValue={selected}
                helpText={
                    <FlexBox alignItems="center" gap="xs">
                        <Text size="xs" color="muted">
                            {`Tags to help categorize your dashboard.`}
                        </Text>
                        <Button variant="ghost" intent="primary" size="flush-xs" onClick={tagModal.setTrue}>
                            {`Create a new tag`}
                        </Button>
                    </FlexBox>
                }
                control={entityControl}
                options={tagOptions ?? []}
            />
        </>
    );
};
