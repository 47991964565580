import { createMutation } from 'react-query-kit';

import { studioTagsApi } from '@blockworks/platform/api/research/studio/tags';

const useCreateTag = createMutation({
    mutationKey: [studioTagsApi.post.createTag.cachePrefix],
    mutationFn: studioTagsApi.post.createTag,
});

const useDeleteTag = createMutation({
    mutationKey: [studioTagsApi.delete.tag.cachePrefix],
    mutationFn: studioTagsApi.delete.tag,
});

const tagMutations = {
    createTag: { use: useCreateTag },
    deleteTag: { use: useDeleteTag },
};

export { tagMutations };
