import { BASE_URLS } from '../../../config/base-urls';
import { createApi } from '../../../config/create-api';
import { stripEmpty } from '../../../config/create-api/build-endpoint.utils';
import { formatOrderParam } from '../../../config/format-order-param';
import { getStudioHeaders } from '../config/headers';

import {
    GetExecutionRequest,
    GetExecutionResponse,
    GetExecutionsRequest,
    GetExecutionsResponse,
    PostExecuteQueryRequest,
    PostExecuteQueryResponse,
    STUDIO_EXECUTION_ENDPOINTS,
} from './execution.model';
import { getExecutionViews } from './execution.utils';

const studioExecutionApi = createApi(build => ({
    get: {
        execution: build<GetExecutionResponse, GetExecutionRequest>({
            cachePrefix: 'execution-by-id',
            queryFn: async ({ id, limit = 50 * 1000, page = 1, filter, order }, { baseFetch }) => {
                let res: GetExecutionResponse = undefined as any;
                try {
                    let url = `${BASE_URLS['research-api']}${STUDIO_EXECUTION_ENDPOINTS.GET.EXECUTION({ id })}`;

                    if (limit || page || order || filter) {
                        const search = new URLSearchParams(
                            stripEmpty({ limit, page, order: formatOrderParam(order), filter }),
                        );
                        url += `?${search.toString()}`;
                    }

                    res = await baseFetch<false, any, GetExecutionResponse>({
                        url,
                        headers: getStudioHeaders(),
                    });
                    if (!res.data) {
                        return res;
                    }

                    return {
                        ...res,
                        data: {
                            ...res.data,
                            metadata: {
                                ...res.data.metadata,
                                ...getExecutionViews(res.data),
                            },
                        },
                    };
                } catch (error) {
                    const err = error as Error;
                    console.error(err);
                }
                return res;
            },
        }),
        executions: build<GetExecutionsResponse, GetExecutionsRequest>({
            cachePrefix: 'all-executions',
            query: ({ queryId, limit = 5, page = 1, ...rest }) => {
                return {
                    uri: STUDIO_EXECUTION_ENDPOINTS.GET.EXECUTIONS(),
                    params: {
                        queryId,
                        limit,
                        page,
                        ...rest,
                    },
                    options: {
                        headers: getStudioHeaders(),
                    },
                };
            },
        }),
    },
    post: {
        executeQuery: build<PostExecuteQueryResponse, PostExecuteQueryRequest>({
            cachePrefix: 'run-execution-by-id',
            query: ({ queryId, userId }) => {
                return {
                    uri: STUDIO_EXECUTION_ENDPOINTS.POST.EXECUTION(),
                    options: {
                        headers: getStudioHeaders(),
                        body: { queryId, userId },
                        method: build.Method.Post,
                    },
                };
            },
        }),
    },
}));

export { studioExecutionApi };
