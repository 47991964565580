import React from 'react';
import { executionQueries } from '@studio/api/execution/queries';
import { queryQueries } from '@studio/api/query/queries';
import { visualizationMutations } from '@studio/api/visualizations/mutations';
import { StudioRoutePath } from '@studio/models/routes';
import { vizTypeSchema } from '@studio/models/visualization-type';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { StudioExecutionState } from '@blockworks/platform/api/research/studio/execution';
import { StudioVisualizationType } from '@blockworks/platform/api/research/studio/visualizations';
import { useBoolean } from '@blockworks/platform/hooks';
import { Form } from '@blockworks/ui/forms/form';

import { CreateOrSelectTags } from '../../create-or-select-tags';
import { CreateEntityModal, type CreateEntityModalConsumerProps } from '../create-entity-modal';

const createVisualizationFormSchema = yup.object({
    title: yup.string().required('A title is required.'),
    queryId: yup.string().optional(),
    subTitle: yup.string(),
    executionId: yup.string().when('queryId', {
        is: (queryId = '') => queryId.length > 0,
        then: () => yup.string().optional(),
        otherwise: () =>
            yup.string().required('A successful query execution is required in order to create a visualization.'),
    }),
    type: vizTypeSchema.required('A type is required.'),
    tagIds: yup.array().of(yup.string().defined()).optional(),
});

const VisualizationCategoryOptions = Object.entries(StudioVisualizationType).map(([key, value]) => ({
    label: key,
    value: value,
}));

type CreateVisualizationModalProps = {
    showQuerySelector?: boolean;
} & CreateEntityModalConsumerProps;

const CreateVisualizationModal = ({
    showQuerySelector = false,
    hideTrigger = false,
    ...controlledModal
}: CreateVisualizationModalProps) => {
    const uncontrolledModal = useBoolean(false);
    const modal = controlledModal.value !== undefined ? controlledModal : uncontrolledModal;

    const router = useRouter();
    const queryClient = useQueryClient();

    const { data: successfulQueries } = queryQueries.getQueries.use({
        variables: { states: [StudioExecutionState.Success] },
        select: ({ data }) => data.map(query => ({ label: query.title, value: query.id })),
    });

    const { data: lastSuccessfulExecution } = executionQueries.getExecutions.use({
        enabled: !!router.query.id && !!modal.value,
        variables: {
            queryId: router.query.id as string,
            state: StudioExecutionState.Success,
            /** no need to fetch results; they're not needed for creating an execution */
            resultLimit: 1,
        },
        select: ({ data }) => data[0],
    });

    const { control, handleSubmit, reset, formState } = Form.use({
        values: {
            executionId: lastSuccessfulExecution?.executionId as string,
        } as any,
        resolver: Form.resolvers.yup(createVisualizationFormSchema),
    });

    const createVisualization = visualizationMutations.createVisualization.use();
    const onSubmit = handleSubmit(async ({ title, subTitle, queryId, type }) => {
        let execution = lastSuccessfulExecution;
        try {
            if (queryId) {
                execution = await queryClient.fetchQuery(
                    executionQueries.getExecutions.use.getFetchOptions({
                        queryId,
                        state: StudioExecutionState.Success,
                        resultLimit: 1,
                    }),
                );
            }

            if (!execution) return false;

            const res = await createVisualization.mutateAsync({
                title,
                type,
                subTitle,
                queryId: showQuerySelector ? queryId : (router.query.id as string),
                lastSuccessfulExecution: execution,
            });
            if (res?.data?.id) {
                modal.setFalse();
                router.push(StudioRoutePath.EditVisualization({ id: res.data.id }));
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    });

    const handleOnOpenChange = (open: boolean) => {
        if (!open) reset();
        modal.setValue(open);
    };

    const handleOnClose = () => reset({ title: '', queryId: '', subTitle: '', executionId: '', tagIds: [] });

    const executionErrorMessage =
        typeof formState.errors.executionId?.message === 'string' ? formState.errors.executionId?.message : undefined;

    return (
        <>
            <CreateEntityModal.Trigger hide={hideTrigger} onClick={modal.setTrue}>
                New Visualization
            </CreateEntityModal.Trigger>
            <CreateEntityModal
                open={modal.value}
                title="New Visualization"
                onOpenChange={handleOnOpenChange}
                errorMessage={executionErrorMessage ?? createVisualization?.error?.message}
                submitProps={{
                    loading: formState.isSubmitting,
                    loadingText: 'Creating Visualization',
                    label: 'Create Visualization',
                    onSubmit: onSubmit,
                }}
                onClose={handleOnClose}
            >
                {!!successfulQueries?.length && showQuerySelector && (
                    <Form.Select
                        placeholder="Query"
                        name="queryId"
                        size="sm"
                        control={control}
                        options={successfulQueries}
                        helpText="Select a query to use as the basis for your visualization."
                    />
                )}
                <Form.Input placeholder="Title" name="title" size="sm" control={control} />
                <Form.Input placeholder="Subtitle" name="subTitle" size="sm" control={control} />
                <CreateOrSelectTags control={control} />
                <Form.Select
                    placeholder="Visualization Type"
                    name="type"
                    size="sm"
                    control={control}
                    options={VisualizationCategoryOptions}
                />
            </CreateEntityModal>
        </>
    );
};

export { CreateVisualizationModal };
