import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const PlusIcon = forwardRef(({
  title,
  titleId,
  color,
  borderRadius,
  ...props
}: IconProps, ref?: LegacyRef<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width={props.width} height={props.height} role="img" aria-hidden="true" className={styles.icon({
  ...props,
  borderRadius,
  color
})} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M8.667 3.333a.667.667 0 0 0-1.334 0v4h-4a.667.667 0 0 0 0 1.333h4v4a.667.667 0 1 0 1.334 0v-4h4a.667.667 0 1 0 0-1.333h-4z" /></svg>);
PlusIcon.displayName = "PlusIcon";
export default PlusIcon;