import { defineEndpoints } from '../../../config/define-endpoints';
import { DataWithMeta, OrSymbol, PaginatedRequest } from '../../../config/type-helpers';

type TagShape = {
    id: string;
    name: string;
    color: string;
};

type GetTagRequest = {
    id: string;
};
type GetTagResponse = DataWithMeta<TagShape>;

type GetTagsRequest = PaginatedRequest<TagShape> & {
    ids?: OrSymbol<string[] | string>;
};
type GetTagsResponse = DataWithMeta<TagShape[]>;

type PostCreateTagRequest = {
    name: string;
    color?: string;
};
type PostCreateTagResponse = DataWithMeta<TagShape>;

type PatchUpdateTagRequest = {
    id: string;
    name?: string;
    color?: string;
};

type PatchUpdateTagResponse = DataWithMeta<TagShape>;

type DeleteTagRequest = {
    id: string;
};

type DeleteTagResponse = DataWithMeta<{
    response: string;
    status: boolean;
}>;

const STUDIO_TAGS_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        TAG: template('/api/v1/studio/tag/{id}'),
        TAGS: template('/api/v1/studio/tag'),
    },
    POST: {
        TAG: template('/api/v1/studio/tag'),
    },
    PATCH: {
        TAG: template('/api/v1/studio/tag/{id}'),
    },
    DELETE: {
        TAG: template('/api/v1/studio/tag/{id}'),
    },
}));

export type {
    DeleteTagRequest,
    DeleteTagResponse,
    GetTagRequest,
    GetTagResponse,
    GetTagsRequest,
    GetTagsResponse,
    PatchUpdateTagRequest,
    PatchUpdateTagResponse,
    PostCreateTagRequest,
    PostCreateTagResponse,
    TagShape,
};

export { STUDIO_TAGS_ENDPOINTS };
