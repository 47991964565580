import { stringify } from 'qs';

import { tryJsonParse } from '../../../../utils';
import { BASE_URLS } from '../../../config/base-urls';
import { createApi } from '../../../config/create-api';
import { formatOrderParam } from '../../../config/format-order-param';
import { makeError } from '../../../config/make-error';
import { getStudioHeaders } from '../config/headers';

import {
    DeleteVisualizationRequest,
    DeleteVisualizationResponse,
    GetVisualizationRequest,
    GetVisualizationResponse,
    GetVisualizationResponseRaw,
    GetVisualizationsRequest,
    GetVisualizationsResponse,
    PatchUpdateVisualizationRequest,
    PostCreateVisualizationRequest,
    PostCreateVisualizationResponse,
    PostUpdateScreenshotRequest,
    PostUpdateScreenshotResponse,
    STUDIO_VISUALIZATIONS_ENDPOINTS,
    StudioVizConfig,
} from './models';
import { initVizConfig } from './utils';

const studioVisualizationsApi = createApi(build => ({
    get: {
        visualization: build<GetVisualizationResponse, GetVisualizationRequest>({
            cachePrefix: 'visualization-by-id',
            queryFn: async ({ id, expand }, { baseFetch }) => {
                try {
                    const baseUrl = BASE_URLS['research-api'];
                    const path = STUDIO_VISUALIZATIONS_ENDPOINTS.GET.VISUALIZATION({ id });
                    const params = stringify({ expand });
                    const vizResponse = await baseFetch<false, any, GetVisualizationResponseRaw>({
                        url: `${baseUrl}${path}?${params}`,
                        headers: getStudioHeaders(),
                    });

                    if (!vizResponse.data) {
                        return makeError();
                    }

                    const { parsedJson: config } = tryJsonParse<StudioVizConfig>(vizResponse.data.config);

                    return {
                        ...vizResponse,
                        data: {
                            ...vizResponse.data,
                            config,
                        },
                    };
                } catch (error) {
                    const err = error as Error;
                    console.error(err);

                    return makeError();
                }
            },
        }),
        visualizations: build<GetVisualizationsResponse, GetVisualizationsRequest>({
            cachePrefix: 'all-visualizations',
            query: ({ userIds, types, queryIds, order, filter, limit = 200 }) => {
                const uri =
                    typeof queryIds === 'string'
                        ? STUDIO_VISUALIZATIONS_ENDPOINTS.GET.VISUALIZATIONS_BY_QUERY({ queryId: queryIds })
                        : STUDIO_VISUALIZATIONS_ENDPOINTS.GET.VISUALIZATIONS();
                return {
                    uri,
                    options: {
                        headers: getStudioHeaders(),
                    },
                    params: {
                        ids: queryIds,
                        userIds,
                        types,
                        order: formatOrderParam(order),
                        filter,
                        /** need to eventually add pagination */
                        limit,
                    },
                };
            },
        }),
    },
    patch: {
        updateVisualization: build<any, PatchUpdateVisualizationRequest>({
            cachePrefix: 'update-visualization',
            query: ({ id, ...body }) => {
                const maybeUpdateConfig = body.config ? { config: JSON.stringify(body.config) } : {};

                return {
                    uri: STUDIO_VISUALIZATIONS_ENDPOINTS.PATCH.VISUALIZATION({ id }),
                    options: {
                        headers: getStudioHeaders(),
                        method: build.Method.Patch,
                        body: {
                            ...body,
                            ...maybeUpdateConfig,
                        },
                    },
                };
            },
        }),
    },
    post: {
        updateScreenshot: build<PostUpdateScreenshotResponse, PostUpdateScreenshotRequest>({
            cachePrefix: 'update-visualization-screenshot',
            query: ({ id, file }) => {
                const formData = new FormData();
                formData.append('file', file);

                return {
                    uri: STUDIO_VISUALIZATIONS_ENDPOINTS.POST.UPDATE_SCREENSHOT({ id }),
                    options: {
                        headers: getStudioHeaders(),
                        method: build.Method.Post,
                        body: formData,
                    },
                };
            },
        }),
        createVisualization: build<PostCreateVisualizationResponse, PostCreateVisualizationRequest>({
            cachePrefix: 'create-visualization',
            queryFn: async (
                { title, subTitle, type, config, queryId, lastSuccessfulExecution, userId },
                { baseFetch },
            ) => {
                try {
                    const createResponse = await baseFetch<false, any, PostCreateVisualizationResponse>({
                        url: `${BASE_URLS['research-api']}${STUDIO_VISUALIZATIONS_ENDPOINTS.POST.VISUALIZATION()}`,
                        method: build.Method.Post,
                        headers: getStudioHeaders(),
                        body: {
                            title,
                            subTitle,
                            type,
                            queryId,
                            config: config ? JSON.stringify(config) : initVizConfig({ type, lastSuccessfulExecution }),
                            userId,
                        },
                    });

                    return createResponse;
                } catch (error) {
                    const err = error as Error;
                    console.error(err);

                    return makeError('Failed to create visualization');
                }
            },
        }),
    },
    delete: {
        visualization: build<DeleteVisualizationResponse, DeleteVisualizationRequest>({
            cachePrefix: 'delete-visualization',
            query: ({ id }) => {
                return {
                    uri: STUDIO_VISUALIZATIONS_ENDPOINTS.DELETE.VISUALIZATION({ id }),
                    options: {
                        headers: getStudioHeaders(),
                        method: build.Method.Delete,
                    },
                };
            },
        }),
    },
}));

export { studioVisualizationsApi };
