import { createQuery } from 'react-query-kit';

import { studioTagsApi } from '@blockworks/platform/api/research/studio/tags';

import { selectTagsAsOptions } from './tag-queries.selectors';

const useGetTag = createQuery({
    queryKey: [studioTagsApi.get.tag.cachePrefix],
    fetcher: studioTagsApi.get.tag,
});

const useGetTags = createQuery({
    queryKey: [studioTagsApi.get.tags.cachePrefix],
    fetcher: studioTagsApi.get.tags,
});

const tagQueries = {
    getTag: { use: useGetTag },
    getTags: { use: useGetTags, select: { selectTagsAsOptions } },
};

export { tagQueries };
